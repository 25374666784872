:root {
  /* 颜色 */
  --theme-color: #ebad29;
  --assist-color: #02a0ea;
  --bg-color: #fbfbfb;
  --font-color: #323232;

  /* 尺寸 */
  --content-width: 1200px;
  --header-height: 70px;
}

/* reset */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  color: var(--font-color);
  font-family: 'Helvetica Neue', Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
  line-height: 1.625;
}

body.has-bgc {
  background-color: var(--bg-color);
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

/* utility */
.wrapper {
  width: var(--content-width);
  margin: 0 auto;
}

/* .center {
  margin: 0 auto;
} */

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

ul.flex,
.listless {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pull-right {
  float: right;
}

.flex > * {
  display: inline-block;
  vertical-align: middle;
  margin-right: -4px;
}

.flex.j-c {
  text-align: center;
}

@supports (display: flex) {
  .flex {
    display: flex;
  }

  .flex > * {
    margin-right: 0;
  }

  .flex::after {
    content: none;
  }

  .pull-right {
    margin-left: auto;
  }

  .flex.a-c {
    align-items: center;
  }

  .flex.j-c {
    justify-content: center;
  }
}

/* 头部 */
.header {
  position: relative;
  z-index: 10;
  min-width: 1200px;
  height: var(--header-height);
  padding: 0 60px;
  outline: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

.header__logo {
  margin-top: 10px;
  height: 40px;
}

@supports (display: flex) {
  .header__logo {
    margin-top: 0;
  }
}

.header-nav ul {
  height: 100%;
}

.header-nav li {
  width: 8.125em;
  text-align: center;
  line-height: var(--header-height);
}

.header-nav a:hover,
.header-nav a.active {
  color: #ecae29;
}

.header-contact {
  width: 190px;
  height: 30px;
  margin-left: 85px;
  background-color: #f9e6be;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  line-height: 30px;
}

.header-contact > span {
  margin-right: 1em;
}

/* 底部 */
.footer {
  height: 130px;
  padding: 10px 0;
  background-color: #f5f5f5;
}

.footer__logo {
  height: 50px;
}

.footer-nav li {
  width: 125px;
  font-size: 14px;
  line-height: 60px;
}

.footer-nav li:last-child {
  width: auto;
}

.footer-nav a:hover {
  color: var(--theme-color);
}

.footer__content small {
  display: block;
  margin-top: 30px;
  font-size: 12px;
  color: #999;
}

.footer__qrcode {
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.footer__qrcode > img {
  width: 80px;
  height: 80px;
}

.footer__content {
  margin: 0 247px;
}

@supports (display: flex) {
  .footer > .wrapper {
    justify-content: space-between;
  }

  .footer__content {
    margin: 0;
  }
}

/* 回到顶部 */
.gototop {
  display: none;
  position: fixed;
  z-index: 11;
  right: 20px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  color: transparent;
  background: var(--theme-color) url("../img/goto-arrow.png") no-repeat center center;
  text-align: center;
  line-height: 50px;
}

.gototop:hover {
  color: #fff;
  background: var(--theme-color);
}

/* 主样式标题 */
.main-title {
  position: relative;
  margin: 45px 0;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-align: center;
}

.main-title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 40px;
  height: 3px;
  margin-top: 16px;
  margin-left: -20px;
  background-color: var(--theme-color);
}
