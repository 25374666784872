/* 主要新闻 */
.main-news > .wrapper {
  min-height: 450px;
  padding: 60px 45px;
  background-color: #fff;
}

.news-figure {
  float: left;
  margin: 0;
}

.news-figure img {
  display: block;
  width: 500px;
  height: 300px;
}

.news-figure > figcaption {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.main-news-list {
  padding-left: 74px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.8;
}

.main-news-list li {
  min-height: 50px;
}

.main-news-list li + li {
  margin-top: 20px;
}

.main-news-list a {
  display: block;
  position: relative;
  padding-left: 28px;
}

.main-news-list a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: 8px;
  border-radius: 50%;
  background-color: #7d7d7d;
}

.main-news-list a > span {
  float: right;
  font-size: 12px;
  line-height: 2.2;
}

.main-news a:hover {
  color: var(--theme-color);
}

.main-news-list a:hover::before {
  background-color: var(--theme-color);
}

/* 图片新闻 */
.pic-news {
  margin: 20px 0;
}

.pic-news > .wrapper {
  padding: 32px 20px;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.pic-news li + li {
  margin-left: 13px;
}

.pic-news-item {
  margin: 0;
}

.pic-news-item img {
  width: 280px;
}

/* 文字新闻 */
.text-news {
  margin: 20px 0;
}

.text-news > .wrapper {
  background-color: #fff;
  padding: 0 20px;
}

.text-news-item {
  padding: 30px 0;
  border-bottom: 1px dashed #d2d2d2;
}

.text-news-item__title {
  font-size: 16px;
  margin: 0;
}

.text-news-item p {
  font-size: 13px;
  line-height: 1.8;
}

.text-news-item__link {
  float: right;
  font-size: 14px;
  color: var(--theme-color);
}

.text-news-item__link:hover {
  border-bottom: 1px solid var(--theme-color);
}

/* 翻页 */
.news-pager {
  padding: 70px 0;
  text-align: center;
}

.news-pager span,
.news-pager a {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  transition: 0.2s;
}

.news-pager a:hover {
  color: var(--theme-color);
  border-color: var(--theme-color);
}

.news-pager span {
  color: #999;
}

.news-pager .current {
  background-color: var(--theme-color);
  color: #fff;
}

.news-pager > * + * {
  margin-left: 8px;
}

.news-pager__assist {
  padding: 0 1em;
}
