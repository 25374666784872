.news-article {
  padding: 20px 80px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  font-size: 14px;
  line-height: 2;

  /* sticky footer 防止新闻内容太短时，页面布局没有铺满屏幕 */
  min-height: calc(100vh - 70px - 130px - 20px - 20px);
}

.news-article__title {
  text-align: center;
}

.news-article__date {
  text-align: right;
}

.news-article figure {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.news-article figcaption {
  font-size: 12px;
  color: #999;
}

.news-article > img {
  display: block;
  max-height: 450px;
  margin: 20px auto 50px;
}

.news-article > p {
  text-indent: 2em;
}
