.curr-sec {
  height: 800px;
}

.curr-sec .wrapper {
  position: relative;
  padding-top: 200px;
}

.curr-sec--1 {
  background-image: url("../img/curr-bg-1.jpg");
}

.curr-sec--2 {
  background-image: url("../img/curr-bg-2.jpg");
}

.curr-sec--3 {
  background-image: url("../img/curr-bg-3.jpg");
}

.curr-sec--4 {
  background-image: url("../img/curr-bg-4.jpg");
}

.curr-sec--5 {
  background-image: url("../img/curr-bg-5.jpg");
}

.curr-sec--1,
.curr-sec--3,
.curr-sec--5 {
  color: #fff;
}

.curr-sec__title {
  position: relative;
  width: 240px;
  height: 80px;
  border: 4px solid var(--theme-color);
  margin: 0;
  font-size: 40px;
  text-align: center;
  line-height: 70px;
}

.curr-sec__title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 32px;
  height: 32px;
  border: 4px solid #fff;
  border-radius: 50%;
  margin-top: -16px;
  margin-left: -18px;
  background-color: var(--theme-color);
}

.curr-sec--1 .curr-sec__title,
.curr-sec--3 .curr-sec__title,
.curr-sec--5 .curr-sec__title {
  border-color: #fff;
}

.curr-sec--1 .curr-sec__title::before,
.curr-sec--3 .curr-sec__title::before,
.curr-sec--5 .curr-sec__title::before {
  background-color: var(--assist-color);
}

.curr-intro > div {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: 0.1em;
}

.curr-intro > div::after {
  content: "";
  display: block;
  clear: both;
}

.curr-intro > div + div {
  margin-top: 5px;
}

.curr-intro > div dt,
.curr-intro > div dd {
  float: left;
}

.curr-intro > div dt::after {
  content: "：";
}

.curr-intro > div dd {
  width: 400px;
  margin: 0;
  text-align: justify;
}

.curr-sec__img {
  position: absolute;
  top: 165px;
  right: 22px;
  box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.1);
  animation: img-fade-in 1s both;
  animation-play-state: paused;
}

.curr-sec__img.run {
  animation-play-state: running;
}

@keyframes img-fade-in {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
}
