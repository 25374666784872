/* 动画 */
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(45deg);
  }
}

@keyframes open-left {
  0% {
    transform: translateX(600px);
  }
}

@keyframes open-right {
  0% {
    transform: translateX(-600px);
  }
}

@keyframes open {
  0% {
    width: 0;
  }
}

/* 首页banner */
.home-banner {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.home-banner--first {
  margin-top: 20px;
}

/* 首页分区 */
.home-sec {
  text-align: center;
  overflow: hidden;
}

.home-sec > .wrapper {
  padding: 10px 0 120px;
}

.home-sec p {
  font-size: 20px;
}

/*
 * 技术核心
 */
:root {
  --figure-width: 142px;
}

.home-tech ul {
  margin-top: 80px;
  font-size: 14px;
}

/* 图形 */
.tech-figure {
  position: relative;
  width: calc(var(--content-width) / 5);
  padding-top: var(--figure-width);
  margin: 0;
}

.tech-figure > img {
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: -30px;
}

.tech-figure::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: var(--figure-width);
  height: var(--figure-width);
  margin-left: calc(var(--figure-width) / 2 * -1);
}

.tech-figure--1::before {
  background-image: url("../img/tech-1.png");
}

.tech-figure--2::before {
  background-image: url("../img/tech-2.png");
}

.tech-figure--3::before {
  background-image: url("../img/tech-3.png");
}

.tech-figure--4::before {
  background-image: url("../img/tech-4.png");
}

.tech-figure--5::before {
  background-image: url("../img/tech-5.png");
}

.tech-figure:hover::before {
  animation: spin 10s linear infinite;
}

.tech-figure > figcaption {
  margin-top: 40px;
}

/*
 * 教学流程对比
 */
.home-compare {
  background-color: #fdf7e9;
}

/* 标题 */
.compare-sec__title {
  margin: 100px 0 50px;
}

.compare-sec__title > div {
  position: relative;
  display: inline-block;
  padding: 3px 30px 3px 50px;
  border: 1px solid #636162;
  font-size: 18px;
  font-weight: normal;
}

.compare-sec__title > div::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 30px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: -5px;
  background-color: #636162;
}

/* 列表 */
.compare-list li {
  position: relative;
  width: 225px;
  padding-top: 60px;
  border-top: 4px solid #d2d2d2;
  margin-left: -1px;
}

.compare-list li:first-child,
.compare-list li:last-child {
  width: 140px;
}

.compare-list li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  border: 5px solid #d2d2d2;
  background-color: #fff;
  border-radius: 50%;
  margin-left: -10px;
  margin-top: -12px;
}

.compare-list li::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 8px;
  height: 22px;
  margin-top: 16px;
  margin-left: -4px;
  background-image: url("../img/compare-icon.png");
}

.compare-list li span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  transform: none\9;
  margin-left: -35px\9;
  margin-top: -28px;
  font-size: 14px;
  white-space: nowrap;
}

.compare-list li:nth-child(2) span {
  transform: translateX(-30%);
  transform: none\9;
}

.compare-list li:last-child span {
  transform: translateX(-80%);
  transform: none\9;
  margin-left: -55px\9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .compare-list li span {
    transform: translateX(-50%);
    margin-left: 0;
  }

  .compare-list li:nth-child(2) span {
    transform: translateX(-30%);
  }

  .compare-list li:last-child span {
    transform: translateX(-80%);
    margin-left: 0;
  }
}

/* 主题色变体 */
.compare-sec--em .compare-sec__title {
  margin-top: 65px;
  color: var(--theme-color);
}

.compare-sec--em .compare-sec__title > div::before {
  background-color: var(--theme-color);
}

.compare-sec--em .compare-sec__title > div,
.compare-sec--em .compare-list li,
.compare-sec--em .compare-list li::before {
  border-color: var(--theme-color);
}

.compare-sec--em .compare-list li::after {
  background-image: url("../img/compare-icon-em.png");
}

/*
 * 个性强化班
 */
:root {
  --circle-width: 340px;
  --half-circle-width: calc(var(--circle-width) / 2);
  --half-content-width: calc(var(--content-width) / 2);
}

/* 两侧图案 */
.home-personal {
  position: relative;
  animation: rotate 1s both 0.5s;
  animation-play-state: paused;
}

.open {
  animation-play-state: running;
}

.home-personal::before,
.home-personal::after {
  content: "";
  position: absolute;
  top: 50%;
  width: var(--half-circle-width);
  height: var(--circle-width);
  margin-top: calc(var(--half-circle-width) * -1);
  background-image: url("../img/personalize-pattern.png");
}

.home-personal::before {
  right: 50%;
  margin-right: var(--half-content-width);
  animation: open-left 3s both 1.5s;
  animation-play-state: inherit;
}

.home-personal::after {
  left: 50%;
  margin-left: var(--half-content-width);
  background-position: right top;
  animation: open-right 3s both 1.5s;
  animation-play-state: inherit;
}

/* 容器层 用于打开动画 */
.home-personal > .wrapper {
  position: relative;
  z-index: 1;
  height: 655px;
  background: #fff url("../img/personalize-bg.png") center bottom;
  overflow: hidden;
  animation: open 3s both 1.5s;
  animation-play-state: inherit;
}

/* 用一个比容器本身高的子元素，达到只有左右两边有阴影的效果 */
.home-personal > .wrapper::before {
  content: "";
  position: absolute;
  top: -25%;
  left: 0%;
  width: 100%;
  height: 200%;
  box-shadow: inset 0 0 10px 20px rgba(0, 0, 0, 0.05);
}

/* 内容层 固定宽度 居中 */
.home-personal__content {
  position: absolute;
  top: 0%;
  left: 50%;
  width: var(--content-width);
  margin-left: calc(var(--half-content-width) * -1);
}

.home-personal__list {
  margin-top: 50px;
}

.personal-item__title {
  color: var(--theme-color);
}

.personal-item p {
  width: 13em;
  font-size: 14px;
  text-align: left;
}

.personal-item + .personal-item {
  margin-left: 200px;
}

/* 金牌师资阵容 */
.home-faculty {
  height: 650px;
  background-image: url("../img/faculty-bg.jpg");
  color: #fff;
}

.faculty-intro {
  margin: 50px 0 10px;
}

.faculty-list {
  list-style: none;
  padding: 0;
  margin: 60px 0 0;
}

.faculty-list li {
  position: relative;
  height: 90px;
  line-height: 80px;
}

.faculty-list li.odd {
  padding-left: 2em;
  margin-left: 600px;
  text-align: left;
}

.faculty-list li.even {
  padding-right: 2em;
  margin-right: 600px;
  text-align: right;
}

.faculty-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 2px;
  height: 120px;
  margin-top: -60px;
  background-color: var(--theme-color);
}

.faculty-list li.odd::before {
  left: -1px;
}

.faculty-list li.even::before {
  right: -1px;
}

.faculty-list li::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-top: -9px;
  background-color: var(--theme-color);
}

.faculty-list li.odd::after {
  left: 0;
  margin-left: -9px;
}

.faculty-list li.even::after {
  right: 0;
  margin-right: -9px;
}

.faculty-list li > span {
  position: absolute;
  top: 26px;
  white-space: nowrap;
}

.faculty-list li.odd > span {
  left: 2em;
}

.faculty-list li.even > span {
  right: 2em;
}
