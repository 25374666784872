/* 动画 */
@keyframes fade1 {
  0% {
    width: 0;
  }

  100% {
    width: 100px;
  }
}

@keyframes fade2 {
  0% {
    width: 0;
  }

  100% {
    width: 200px;
  }
}

@keyframes fade3 {
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes list-fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

/*
 * 文字介绍
 */
.tech-intro {
  padding: 45px 0;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.1em;
}

.tech-intro__title {
  font-weight: normal;
}

/*
 * 智能技术
 */
.tech-detail {
  margin: 20px 0;
}

.tech-detail > .wrapper {
  padding: 0 60px;
  background-color: #fff;
  overflow: hidden;
}

/* 主内容区 */
.tech-presentaion {
  position: relative;
  margin-top: 140px;
}

/* 指示线 */
.line-1,
.line-2 {
  position: absolute;
  height: 2px;
  background-color: var(--assist-color);
}

.line-1 {
  top: 41px;
  left: 300px;
  width: 100px;
  transform: rotate(-45deg);
  transform-origin: left top;
  animation: fade1 0.5s 0.5s both ease-out;
}

.line-2 {
  left: 370px;
  top: -30px;
  width: 200px;
  animation: fade2 0.5s 1s both ease-out;
}

.line-1::after,
.line-2::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -6px;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  border: 1px solid var(--assist-color);
  border-radius: 50%;
}

.line-1::after {
  left: -6px;
}

.line-2::after {
  visibility: hidden;
  opacity: 0;
  animation: fade3 0.5s 1.5s both;
}

/* 左侧控制体 */
:root {
  --control-width: 14em;
  --half-control-width: calc(var(--control-width) / 2);
  --translate-z: calc(var(--control-width) / 5.656862784711924);
}

.tech-control {
  float: left;
  position: relative;
  width: var(--control-width);
  height: var(--control-width);
  border: 1px solid var(--assist-color);
  border-radius: 50%;
  margin-left: 40px;
  background-image: url("../img/ani-bg.jpg");
  background-size: 224px 224px;
  background-position: center center;
  transform: scale(1.2) translateX(-6%);
}

@supports (display: flex) {
  .tech-control {
    background-image: none;
  }
}

/* 3D动画体 */
polygon {
  fill: transparent;
  stroke: var(--assist-color);
  stroke-width: 20px;
}

.tetrahedron {
  margin-left: var(--half-control-width);
  margin-top: 6.9em;
  transform-style: preserve-3d;
  transform: rotateX(36deg) rotateY(0deg) rotateZ(0deg);
  transition: transform 1s;
}

.tetrahedron svg {
  position: absolute;
  margin: calc(var(--half-control-width) * -1);
  width: var(--control-width);
  height: var(--control-width);
}

.tetrahedron svg:nth-child(1) {
  transform: rotateY(-90deg) rotateX(-35.26439deg) translateZ(var(--translate-z));
}

.tetrahedron svg:nth-child(2) {
  transform: rotateY(90deg) rotateX(-35.26439deg) translateZ(var(--translate-z));
}

.tetrahedron svg:nth-child(3) {
  transform: rotate(0.5turn) rotateY(0deg) rotateX(-35.26439deg) translateZ(var(--translate-z));
}

.tetrahedron svg:nth-child(4) {
  transform: rotate(0.5turn) rotateY(180deg) rotateX(-35.26439deg) translateZ(var(--translate-z));
}

.phase-2 .tetrahedron {
  transform: translate(-5.2em, -1em) rotateX(89.9deg) rotateY(30deg) rotateZ(-125deg);
}

.phase-3 .tetrahedron {
  transform: translate(-6.9em, 0) rotateX(145deg) rotateY(0deg) rotateZ(-180deg);
}

.phase-4 .tetrahedron {
  transform: translate(-1.9em, 0.9em) rotateX(-90deg) rotateY(-30deg) rotateZ(-56deg);
}

/* 动画标签 */
.t-label {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 26px;
  margin-top: -15px;
  margin-left: -37px;
  border-radius: 10px;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  transition: transform 1s;
  cursor: pointer;
}

.t-label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--theme-color);
  transition: 0.2s;
}

.t-label:hover::after {
  transform: scale(1.15);
}

.t-label--1 {
  transform: translate(8em, -4.6em);
}

.t-label--2 {
  transform: translate(-8em, -4.6em);
}

.t-label--4 {
  transform: translate(0, 9em);
}

.phase-2 .t-label--1 {
  transform: translate(-8em, -4.6em);
}

.phase-2 .t-label--2 {
  transform: translate(0, 0);
}

.phase-2 .t-label--3 {
  transform: translate(8em, -4.6em);
}

.phase-3 .t-label--1 {
  transform: translate(0, 0);
}

.phase-3 .t-label--2 {
  transform: translate(8em, -4.6em);
}

.phase-3 .t-label--3 {
  transform: translate(-8em, -4.6em);
}

.phase-4 .t-label--1 {
  transform: translate(0, 0);
}

.phase-4 .t-label--2 {
  transform: translate(-8em, -4.6em);
}

.phase-4 .t-label--3 {
  transform: translate(0, 9em);
}

.phase-4 .t-label--4 {
  transform: translate(8em, -4.6em);
}

/* 右侧列表 */
.tech-list {
  float: right;
  position: relative;
  width: 694px;
  height: 500px;
  transform-origin: center top;
  animation: list-fade-in 2s 1.5s both ease-out;
}

.tech-item {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px 70px;
  transform: translateY(10%);
}

.tech-item--1 {
  background-image: url("../img/tech-bg-1.png");
}

.tech-item--2 {
  background-image: url("../img/tech-bg-2.png");
}

.tech-item--3 {
  background-image: url("../img/tech-bg-3.png");
}

.tech-item--4 {
  background-image: url("../img/tech-bg-4.png");
}

.phase-1 .tech-item--1,
.phase-2 .tech-item--2,
.phase-3 .tech-item--3,
.phase-4 .tech-item--4 {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 1s;
}

.tech-item__title {
  position: relative;
  float: left;
  font-weight: normal;
  letter-spacing: 0.1em;
}

.tech-item__title::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  margin-top: 12px;
  background-color: var(--theme-color);
}

.tech-item > p {
  clear: left;
  padding-top: 1em;
  font-size: 18px;
}
